import React, { useState, useEffect } from "react";
import classNames from "classnames";
import { Link } from "gatsby";
import SectionContent from "../components/sectionContent";
import Logo from "../components/logo";
import NavigationLink from "../components/navigationLink";
import Phone from "../components/phone";
import BurgerButton from "../components/burgerButton";
import getNavigationData from "../utils/getNavigationData";
import isBrowser from "../utils/isBrowser";
import scrollToSection from "../utils/scrollToSection";
import * as styles from "./header.module.css";

const Header = ({ content }) => {
  const { links, phone } = getNavigationData(content);
  const [isShadowActive, setIsShadowActive] = useState(false);
  const [isMobileMenuActive, setIsMobileMenuActive] = useState(false);

  useEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const handleScroll = () =>
      setIsShadowActive(document.documentElement.scrollTop > 0);
    handleScroll();

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <header
      className={classNames(styles.header, {
        [styles.headerShadow]: isShadowActive,
        [styles.headerMobileActive]: isMobileMenuActive,
      })}
    >
      <SectionContent>
        <div className={styles.content}>
          <nav className={styles.navigation}>
            <Link to="/" onClick={(e) => scrollToSection(e)}>
              <Logo />
            </Link>
            <ul className={styles.linksList}>
              {links.map((link) => (
                <li key={link.href} className={styles.linksListItem}>
                  <NavigationLink
                    {...link}
                    onClick={(e) => {
                      scrollToSection(e);
                      setIsMobileMenuActive(false);
                    }}
                  />
                </li>
              ))}
            </ul>
            <Phone {...phone} />
            <BurgerButton
              isOpened={isMobileMenuActive}
              onClick={() => setIsMobileMenuActive(!isMobileMenuActive)}
            />
          </nav>
        </div>
      </SectionContent>
    </header>
  );
};

export default Header;
