import React, { Fragment } from "react";
import { StaticImage } from "gatsby-plugin-image";
import getIdFromLink from "../utils/getIdFromLink";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import processText from "../utils/processText";
import processNewLines from "../utils/processNewLines";
import List from "../components/list";
import brandImage4Src from "../assets/extra-classes-brand-4.svg";
import * as styles from "./extraClasses.module.css";

const ExtraClasses = ({ link, heading, content }) => (
  <section id={getIdFromLink(link)} className={styles.section}>
    <SectionContent>
      <div className={styles.sectionHeadingWrapper}>
        <SectionHeading text={processText(heading)} />
        <div className={styles.brandImageWrapper1}>
          <StaticImage
            src="../assets/extra-classes-brand-1.png"
            alt=""
            className={styles.brandImage}
          />
        </div>
      </div>
    </SectionContent>
    <SectionContent isFlex>
      <div className={styles.firstPart}>
        <p className={styles.text}>
          {content[0].primaryText.childMarkdownRemark.htmlAst.children.map(
            ({ value, children }, childIndex) => {
              if (value === "\n") {
                return (
                  <Fragment key={childIndex}>
                    <br /> <br />
                  </Fragment>
                );
              } else {
                return (
                  <Fragment key={childIndex}>
                    {processNewLines(processText(children[0].value))}
                  </Fragment>
                );
              }
            }
          )}
        </p>
        <div className={styles.listWrapper}>
          <List
            items={content[0].secondaryText.childMarkdownRemark.htmlAst.children[0].children
              .filter(({ tagName }) => tagName === "li")
              .map(({ children }) => processText(children[0].value))}
            isSmall
            use2Columns
          />
        </div>
      </div>
      <div className={styles.secondPart}>
        <StaticImage src="../assets/extra-classes.png" alt="" />
        <div className={styles.brandImageWrapper2}>
          <StaticImage
            src="../assets/extra-classes-brand-2.png"
            alt=""
            className={styles.brandImage}
          />
        </div>
        <div className={styles.brandImageWrapper3}>
          <StaticImage
            src="../assets/extra-classes-brand-3.png"
            alt=""
            className={styles.brandImage}
          />
        </div>
        <div className={styles.brandImageWrapper4}>
          <img src={brandImage4Src} alt="" className={styles.brandImage} />
        </div>
      </div>
    </SectionContent>
  </section>
);

export default ExtraClasses;
