import React, { Fragment } from "react";
import classNames from "classnames";
import * as styles from "./textWithHeading.module.css";

const TextWithHeading = ({ TagName, heading, text, isTextInsideHeading }) =>
  isTextInsideHeading ? (
    <TagName className={styles.heading}>
      {heading}
      <span className={styles.text}>{text}</span>
    </TagName>
  ) : (
    <>
      <TagName className={styles.heading}>{heading}</TagName>
      {Array.isArray(text) ? (
        <p className={classNames(styles.text, styles.textLong)}>
          {text.map((textElement, index) => (
            <Fragment key={textElement}>
              {textElement}
              {text.length > 1 && index !== text.length - 1 && (
                <>
                  <br />
                  <br />
                </>
              )}
            </Fragment>
          ))}
        </p>
      ) : (
        <p className={styles.text}>{text}</p>
      )}
    </>
  );

export default TextWithHeading;
