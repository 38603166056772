// extracted by mini-css-extract-plugin
export var firstLine = "reviews-module--firstLine--bJUz4";
export var headings = "reviews-module--headings--IurqN";
export var image = "reviews-module--image--bzX9q";
export var imageWrapper = "reviews-module--imageWrapper--gfX0z";
export var item = "reviews-module--item--F+vmS";
export var listWrapper = "reviews-module--listWrapper--GRDaz";
export var nameHeading = "reviews-module--nameHeading--L1H-D";
export var section = "reviews-module--section--7c8QA";
export var text = "reviews-module--text--Q1UCq";
export var textWrapper = "reviews-module--textWrapper--6Jrmf";
export var titleHeading = "reviews-module--titleHeading--IysYA";