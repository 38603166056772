import React from "react";
import classNames from "classnames";
import * as styles from "./list.module.css";

const List = ({
  items,
  isLinear = false,
  isSmall = false,
  use2Columns = false,
}) => (
  <ul
    className={classNames(styles.list, { [styles.list2Columns]: use2Columns })}
  >
    {items.map((item) => (
      <li
        key={item}
        className={classNames(styles.listItem, {
          [styles.listItemLinear]: isLinear,
          [styles.listItemSmall]: isSmall,
        })}
      >
        {item}
      </li>
    ))}
  </ul>
);

export default List;
