import React from "react";
import { INLINES } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import getIdFromLink from "../utils/getIdFromLink";
import SectionHeading from "../components/sectionHeading";
import SectionContent from "../components/sectionContent";
import processText from "../utils/processText";
import * as styles from "./orgInfo.module.css";

const OPTIONS = {
  renderNode: {
    [INLINES.HYPERLINK]: (node, children) => {
      const { uri } = node.data;

      return (
        <a href={uri} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    },
    [INLINES.ASSET_HYPERLINK]: (node, children) => {
      const { publicUrl } = node.data.target;

      return (
        <a href={publicUrl} target="_blank" rel="noopener noreferrer">
          {children}
        </a>
      );
    },
  },
};

const OrgInfo = ({ link, heading, content, allContentfulAsset }) => (
  <section id={getIdFromLink(link)} className={styles.section}>
    <SectionContent>
      <SectionHeading text={processText(heading)} />
      <div className={styles.content}>
        {renderRichText(content[0].richText, OPTIONS)}
      </div>
    </SectionContent>
  </section>
);

export default OrgInfo;
