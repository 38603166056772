import React from "react";
import classNames from "classnames";
import { BRAND_NAME } from "../constants/constants";
import logoSrc from "../assets/logo.svg";
import logoLargeSrc from "../assets/logo-large.svg";
import * as styles from "./logo.module.css";

const Logo = ({ isLarge = false, isFooter = false }) => (
  <>
    <img
      src={isLarge ? logoLargeSrc : logoSrc}
      alt=""
      className={classNames(styles.logo, {
        [styles.logoLarge]: isLarge,
        [styles.logoFooter]: isFooter,
      })}
    />
    {isFooter && (
      <small
        className={styles.copyright}
      >{`© ${BRAND_NAME}, ${new Date().getFullYear()}`}</small>
    )}
  </>
);

export default Logo;
