// extracted by mini-css-extract-plugin
export var actionWrapper = "mainInfo-module--actionWrapper--NRlLQ";
export var brandImageWrapper = "mainInfo-module--brandImageWrapper--noEmA";
export var buttonWrapper = "mainInfo-module--buttonWrapper--OHkJs";
export var content = "mainInfo-module--content--DBEAw";
export var firstPart = "mainInfo-module--firstPart--GHt0d";
export var h1 = "mainInfo-module--h1--EIiut";
export var h1Background = "mainInfo-module--h1Background--wR7pH";
export var h1Wrapper = "mainInfo-module--h1Wrapper--QQoH4";
export var h2Wrapper = "mainInfo-module--h2Wrapper--OzOQ3";
export var h3 = "mainInfo-module--h3--sBsfl";
export var imageWrapper = "mainInfo-module--imageWrapper--7s+iC";
export var secondPart = "mainInfo-module--secondPart--l05uJ";