// extracted by mini-css-extract-plugin
export var brandImage = "guarantees-module--brandImage--naxiT";
export var brandImageWrapper1 = "guarantees-module--brandImageWrapper1--IeuHt";
export var brandImageWrapper2 = "guarantees-module--brandImageWrapper2--pO2iv";
export var brandImageWrapper3 = "guarantees-module--brandImageWrapper3--TtGht";
export var brandImageWrapper4 = "guarantees-module--brandImageWrapper4--+OO0l";
export var item = "guarantees-module--item--sdJBh";
export var itemLogo = "guarantees-module--itemLogo--6dHe8";
export var list = "guarantees-module--list--Zb-Hf";
export var listWrapper = "guarantees-module--listWrapper--qR47Z";
export var section = "guarantees-module--section--Blut3";