import React from "react";
import { graphql } from "gatsby";
import * as Sections from "../sections";
import Page from "../components/page";
import Main from "../components/main";

export const query = graphql`
  query ($id: String!) {
    page: contentfulPage(id: { eq: $id }) {
      title
      description
      keywords
      image {
        file {
          url
        }
      }
      noIndex
      sections {
        __typename
        id
        component
        heading
        secondaryHeading
        link {
          href
        }
        content {
          __typename
          ... on ContentfulContent {
            id
            primaryText {
              childMarkdownRemark {
                htmlAst
              }
            }
            secondaryText {
              childMarkdownRemark {
                rawMarkdownBody
                htmlAst
              }
            }
            image {
              file {
                url
              }
              gatsbyImageData
            }
            links {
              id
              href
              text
              accent
              footerOnly
            }
          }
          ... on ContentfulListOfReferences {
            id
            name
            items {
              ... on ContentfulContactItem {
                type
                value
                text
                icon {
                  file {
                    url
                  }
                }
              }
              ... on ContentfulContentItem {
                heading
                secondHeading
                icon {
                  file {
                    url
                  }
                }
                image {
                  gatsbyImageData
                }
                mdText: text {
                  childMarkdownRemark {
                    htmlAst
                  }
                }
              }
            }
          }
          ... on ContentfulRichTextContent {
            id
            richText {
              raw
              references {
                __typename
                contentful_id
                publicUrl
              }
            }
          }
        }
      }
    }
  }
`;

const ContentfulPage = ({ data: { page } }) => {
  let headerElement = null;
  let footerElement = null;
  const mainSectionsElements = [];

  for (const section of page.sections) {
    const Section = Sections[section.component];
    const componentElement = <Section key={section.id} {...section} />;

    if (section.component === "Header") {
      headerElement = componentElement;
    } else if (section.component === "Footer") {
      footerElement = componentElement;
    } else {
      mainSectionsElements.push(componentElement);
    }
  }

  return (
    <Page {...page}>
      {headerElement}
      <Main>{mainSectionsElements}</Main>
      {footerElement}
    </Page>
  );
};

export default ContentfulPage;
