// extracted by mini-css-extract-plugin
export var brandImage = "extraClasses-module--brandImage--z1dt8";
export var brandImageWrapper1 = "extraClasses-module--brandImageWrapper1--mHuqe";
export var brandImageWrapper2 = "extraClasses-module--brandImageWrapper2--s2wpN";
export var brandImageWrapper3 = "extraClasses-module--brandImageWrapper3--qeu8x";
export var brandImageWrapper4 = "extraClasses-module--brandImageWrapper4--A9e1J";
export var firstPart = "extraClasses-module--firstPart--sdRK4";
export var listWrapper = "extraClasses-module--listWrapper--VKiaY";
export var secondPart = "extraClasses-module--secondPart--JFqje";
export var section = "extraClasses-module--section--B+iPd";
export var sectionHeadingWrapper = "extraClasses-module--sectionHeadingWrapper--fHvy7";
export var text = "extraClasses-module--text--Q75fE";