import React from "react";
import ReactSlick from "react-slick";
import { SIZES } from "../utils/useBreakpoints";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./slider.css";

const SETTINGS = {
  arrows: true,
  slidesToShow: 2,
  responsive: [
    {
      breakpoint: SIZES.gtM,
      settings: {
        slidesToShow: 1,
      },
    },
  ],
};

const Slider = ({ children }) => (
  <ReactSlick {...SETTINGS}>{children}</ReactSlick>
);

export default Slider;
