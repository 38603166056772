import { navigate } from "gatsby";
import smoothscroll from "smoothscroll-polyfill";
import isBrowser from "./isBrowser";

if (isBrowser()) {
  smoothscroll.polyfill();
}

const scrollToSection = (e) => {
  const { hash, pathname } = e.currentTarget;

  if (!hash && pathname !== "/") {
    return;
  }

  e.preventDefault();

  const scroll = () => {
    if (hash) {
      window.scroll({
        top:
          document.querySelector(hash).offsetTop -
          document.querySelector("header").offsetHeight -
          20,
        behavior: "smooth",
      });
    } else {
      window.scroll({ top: 0, behavior: "smooth" });
    }
  };

  if (window.location.pathname === "/") {
    scroll();
  } else {
    navigate(pathname);
    window.setTimeout(scroll, 500);
  }
};

export default scrollToSection;
