import React, { useState } from "react";
import classNames from "classnames";
import expandIconSrc from "../assets/expand.svg";
import * as styles from "./expandableText.module.css";

const ExpandableText = ({ icon = null, heading, text, isFullOnGtM = true }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  return (
    <div
      className={classNames(styles.item, {
        [styles.itemExpanded]: isExpanded,
        [styles.itemWithoutIcon]: !icon,
        [styles.itemFullOnGtM]: isFullOnGtM,
      })}
    >
      <div className={styles.firstRow}>
        {icon && (
          <div className={styles.iconWrapper}>
            <img src={icon.file.url} alt="" className={styles.icon} />
          </div>
        )}
        <h3 className={styles.heading}>{heading}</h3>
        <button
          type="button"
          className={styles.button}
          onClick={() => setIsExpanded(!isExpanded)}
        >
          <img src={expandIconSrc} alt="" className={styles.expandIcon} />
        </button>
      </div>
      <p className={styles.text}>{text}</p>
    </div>
  );
};

export default ExpandableText;
