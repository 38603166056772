// extracted by mini-css-extract-plugin
export var brandImage = "schedule-module--brandImage--u80iE";
export var brandImageWrapper1 = "schedule-module--brandImageWrapper1--gMA7q";
export var brandImageWrapper2 = "schedule-module--brandImageWrapper2--Ye+9B";
export var content = "schedule-module--content--RVFlI";
export var firstPart = "schedule-module--firstPart--3TZVP";
export var imageWrapper = "schedule-module--imageWrapper--M0xww";
export var item = "schedule-module--item--2oUwm";
export var itemHeading = "schedule-module--itemHeading--zV+LR";
export var itemText = "schedule-module--itemText--wSJ8U";
export var list = "schedule-module--list--2-5n+";
export var listWrapper = "schedule-module--listWrapper--lMILh";
export var secondPart = "schedule-module--secondPart--oIyZR";
export var section = "schedule-module--section--V-kb7";