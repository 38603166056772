import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import * as styles from "./navigationLink.module.css";

const NavigationLink = ({
  href,
  text,
  onClick,
  accent = false,
  isFooter = false,
}) => (
  <Link
    to={href}
    onClick={onClick}
    className={classNames(styles.navigationLink, {
      [styles.navigationLinkAccent]: !isFooter && accent,
    })}
  >
    {text}
  </Link>
);

export default NavigationLink;
