// extracted by mini-css-extract-plugin
export var buttonWrapper = "callback-module--buttonWrapper--LCTEG";
export var error = "callback-module--error--Zixd4";
export var form = "callback-module--form--fNaDX";
export var heading = "callback-module--heading---DJIJ";
export var input = "callback-module--input--95Odu";
export var inputInvalid = "callback-module--inputInvalid--b4xXN";
export var inputWrapper = "callback-module--inputWrapper--QYNY+";
export var inputs = "callback-module--inputs--rhmHa";
export var mainContent = "callback-module--mainContent--+nSk4";
export var section = "callback-module--section--iOqec";
export var text = "callback-module--text--koNuT";