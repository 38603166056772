import React, { Fragment, useLayoutEffect, useMemo, useRef } from "react";
import { StaticImage } from "gatsby-plugin-image";
import getIdFromLink from "../utils/getIdFromLink";
import processText from "../utils/processText";
import processNewLines from "../utils/processNewLines";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import useBreakpoints from "../utils/useBreakpoints";
import ExpandableText from "../components/expandableText";
import Logo from "../components/logo";
import brandImageSrc1 from "../assets/guarantees-brand-1.svg";
import brandImageSrc2 from "../assets/guarantees-brand-2.svg";
import * as styles from "./guarantees.module.css";

const Guarantees = ({ link, heading, content }) => {
  const listRef = useRef();
  const items = useMemo(() => {
    const items = [...content[0].items];
    items.splice(7, 0, { isLogo: true });
    return items;
  }, [content]);
  const { gtM } = useBreakpoints();

  useLayoutEffect(() => {
    if (!gtM) {
      return;
    }

    for (const itemElement of listRef.current.querySelectorAll("li")) {
      itemElement.style =
        itemElement.offsetHeight >= 250 &&
        !itemElement.classList.contains(styles.itemLogo)
          ? "grid-row-end: span 2"
          : "";
    }
  }, [items, gtM]);

  return (
    <section id={getIdFromLink(link)} className={styles.section}>
      <SectionContent>
        <SectionHeading text={processText(heading)} />
        <div className={styles.listWrapper}>
          <div className={styles.brandImageWrapper1}>
            <img src={brandImageSrc1} alt="" className={styles.brandImage} />
          </div>
          <ul className={styles.list} ref={listRef}>
            {items.map(({ icon, heading, mdText, isLogo }, itemIndex) => {
              if (isLogo) {
                return (
                  <li
                    key={itemIndex}
                    className={[styles.item, styles.itemLogo].join(" ")}
                  >
                    <div className={styles.brandImageWrapper2}>
                      <img
                        src={brandImageSrc2}
                        alt=""
                        className={styles.brandImage}
                      />
                    </div>
                    <div className={styles.brandImageWrapper3}>
                      <StaticImage
                        src="../assets/guarantees-brand-3.png"
                        alt=""
                        className={styles.brandImage}
                      />
                    </div>
                    <div className={styles.brandImageWrapper4}>
                      <StaticImage
                        src="../assets/guarantees-brand-4.png"
                        alt=""
                        className={styles.brandImage}
                      />
                    </div>
                    <Logo isLarge />
                  </li>
                );
              }

              const textArray = mdText.childMarkdownRemark.htmlAst.children.map(
                ({ value, children }, childIndex) => {
                  if (value === "\n") {
                    return (
                      <Fragment key={childIndex}>
                        <br /> <br />
                      </Fragment>
                    );
                  } else {
                    return (
                      <Fragment key={childIndex}>
                        {processNewLines(processText(children[0].value))}
                      </Fragment>
                    );
                  }
                }
              );

              return (
                <li key={itemIndex} className={styles.item}>
                  <ExpandableText
                    icon={icon}
                    heading={processText(heading)}
                    text={textArray}
                  />
                </li>
              );
            })}
          </ul>
        </div>
      </SectionContent>
    </section>
  );
};

export default Guarantees;
