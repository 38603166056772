import React, { Fragment } from "react";
import { StaticImage } from "gatsby-plugin-image";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import processText from "../utils/processText";
import processNewLines from "../utils/processNewLines";
import ExpandableText from "../components/expandableText";
import getIdFromLink from "../utils/getIdFromLink";
import brandImage2Src from "../assets/faq-brand-2.svg";
import * as styles from "./faq.module.css";

const FAQ = ({ link, heading, content }) => (
  <section id={getIdFromLink(link)} className={styles.section}>
    <SectionContent>
      <SectionHeading text={processText(heading)} />
      <div className={styles.listWrapper}>
        <div className={styles.brandImageWrapper1}>
          <StaticImage
            src="../assets/faq-brand-1.png"
            alt=""
            className={styles.brandImage}
          />
        </div>
        <div className={styles.brandImageWrapper2}>
          <img src={brandImage2Src} alt="" className={styles.brandImage} />
        </div>
        <ul className={styles.list}>
          {content[0].items.map(({ heading, mdText }, itemIndex) => {
            const textArray = mdText.childMarkdownRemark.htmlAst.children.map(
              ({ value, children }, childIndex) => {
                if (value === "\n") {
                  return (
                    <Fragment key={childIndex}>
                      <br /> <br />
                    </Fragment>
                  );
                } else {
                  return (
                    <Fragment key={childIndex}>
                      {processNewLines(processText(children[0].value))}
                    </Fragment>
                  );
                }
              }
            );

            return (
              <li key={itemIndex} className={styles.item}>
                <ExpandableText
                  heading={processText(heading)}
                  text={textArray}
                  isFullOnGtM={false}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </SectionContent>
  </section>
);

export default FAQ;
