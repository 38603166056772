import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import scrollToSection from "../utils/scrollToSection";
import * as styles from "./button.module.css";

const Button = ({
  children,
  href = null,
  type = "button",
  isWhite = false,
  isDisabled = false,
}) =>
  href ? (
    <Link
      to={href}
      onClick={scrollToSection}
      className={classNames(styles.button, { [styles.buttonWhite]: isWhite })}
    >
      {children}
    </Link>
  ) : (
    <button
      type={type}
      disabled={isDisabled}
      className={classNames(styles.button, { [styles.buttonWhite]: isWhite })}
    >
      {children}
    </button>
  );

export default Button;
