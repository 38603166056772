import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SectionContent from "../components/sectionContent";
import Button from "../components/button";
import processText from "../utils/processText";
import TextWithHeading from "../components/textWithHeading";
import { CALLBACK_SECTION_LINK } from "../constants/constants";
import * as styles from "./mainInfo.module.css";

const MainInfo = ({ content }) => (
  <section>
    <SectionContent>
      <div className={styles.content}>
        {content.reduce((result, { __typename, primaryText }) => {
          if (__typename === "ContentfulContent") {
            const items = primaryText.childMarkdownRemark.htmlAst.children;
            const firstHeading = items[0];
            const secondHeading = items[2];
            const thirdHeading = items[4];
            const button = items[6].children[0];

            result.push(
              <div className={styles.firstPart} key="first-part">
                <div className={styles.h1Wrapper}>
                  <div className={styles.brandImageWrapper}>
                    <StaticImage src="../assets/main-info-brand.png" alt="" />
                  </div>
                  <div className={styles.h1Background} />
                  <firstHeading.tagName className={styles.h1}>
                    {processText(firstHeading.children[0].value)}{" "}
                    <em>
                      {processText(firstHeading.children[1].children[0].value)}
                    </em>{" "}
                    {processText(firstHeading.children[2].value)}
                  </firstHeading.tagName>
                </div>
                <div className={styles.h2Wrapper}>
                  <TextWithHeading
                    TagName={secondHeading.tagName}
                    heading={processText(
                      secondHeading.children[0].children[0].value
                    )}
                    text={processText(secondHeading.children[1].value)}
                    isTextInsideHeading
                  />
                </div>
                <div className={styles.actionWrapper}>
                  <thirdHeading.tagName className={styles.h3}>
                    {
                      <img
                        src={thirdHeading.children[0].properties.src}
                        alt=""
                      />
                    }
                    {processText(thirdHeading.children[1].value)}
                    <br />
                    {processText(thirdHeading.children[3].value)}
                  </thirdHeading.tagName>
                  <div className={styles.buttonWrapper}>
                    <Button href={CALLBACK_SECTION_LINK}>
                      {processText(button.children[0].value)}
                    </Button>
                  </div>
                </div>
              </div>
            );
          }

          result.push(
            <div className={styles.secondPart} key="second-part">
              <div className={styles.imageWrapper}>
                <StaticImage src="../assets/main-info.png" alt="" />
              </div>
            </div>
          );

          return result;
        }, [])}
      </div>
    </SectionContent>
  </section>
);

export default MainInfo;
