// extracted by mini-css-extract-plugin
export var brandImage = "values-module--brandImage--23Lad";
export var brandImageWrapper1 = "values-module--brandImageWrapper1--2+Nrx";
export var brandImageWrapper2 = "values-module--brandImageWrapper2--ha8+F";
export var brandImageWrapper3 = "values-module--brandImageWrapper3--I2ppB";
export var brandImageWrapper4 = "values-module--brandImageWrapper4--sCFK3";
export var brandImageWrapper5 = "values-module--brandImageWrapper5--dtxt6";
export var brandImageWrapper6 = "values-module--brandImageWrapper6--x4KpQ";
export var brandImageWrapper7 = "values-module--brandImageWrapper7--twjaj";
export var brandImageWrapper8 = "values-module--brandImageWrapper8--NmxyK";
export var heading = "values-module--heading--5Hn0u";
export var icon = "values-module--icon--17pGE";
export var iconWrapper = "values-module--iconWrapper--M65Fs";
export var image = "values-module--image--lmjx9";
export var imageWrapper = "values-module--imageWrapper--u5XCe";
export var item = "values-module--item--KTg3T";
export var list = "values-module--list--aI7kb";
export var listWrapper = "values-module--listWrapper--nEhFC";
export var section = "values-module--section--M4tn3";
export var text = "values-module--text--OW1de";
export var textWithHeading = "values-module--textWithHeading--scxDk";