// extracted by mini-css-extract-plugin
export var button = "expandableText-module--button--UwJa1";
export var expandIcon = "expandableText-module--expandIcon--wA2bx";
export var firstRow = "expandableText-module--firstRow--eXrJc";
export var heading = "expandableText-module--heading--0+Vc8";
export var icon = "expandableText-module--icon--rN202";
export var iconWrapper = "expandableText-module--iconWrapper--nWgHU";
export var item = "expandableText-module--item--ZW25e";
export var itemExpanded = "expandableText-module--itemExpanded--xcFjl";
export var itemFullOnGtM = "expandableText-module--itemFullOnGtM--XsQIH";
export var itemWithoutIcon = "expandableText-module--itemWithoutIcon--QSeug";
export var text = "expandableText-module--text---cnKT";