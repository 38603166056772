import React from "react";
import * as styles from "./factors.module.css";

const Factors = ({ heading, items }) => (
  <section className={styles.section}>
    <h3 className={styles.heading}>{heading}</h3>
    <ul className={styles.list}>
      {items.map((item) => (
        <li key={item.heading} className={styles.listItem}>
          <div className={styles.imageWrapper}>
            <img src={item.icon.file.url} alt="" className={styles.image} />
          </div>
          <h4 className={styles.itemHeading}>{item.heading}</h4>
        </li>
      ))}
    </ul>
  </section>
);

export default Factors;
