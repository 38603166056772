import React, { Fragment } from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import Slider from "../components/slider";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import processText from "../utils/processText";
import processNewLines from "../utils/processNewLines";
import ScrollableText from "../components/scrollableText";
import getIdFromLink from "../utils/getIdFromLink";
import * as styles from "./reviews.module.css";

const Reviews = ({ link, heading, content }) => (
  <section id={getIdFromLink(link)} className={styles.section}>
    <SectionContent>
      <SectionHeading text={processText(heading)} />
      <div className={styles.listWrapper}>
        <Slider>
          {content[0].items.map(
            ({ heading, secondHeading, mdText, image }, itemIndex) => {
              const textArray = mdText.childMarkdownRemark.htmlAst.children.map(
                ({ value, children }, childIndex) => {
                  if (value === "\n") {
                    return (
                      <Fragment key={childIndex}>
                        <br /> <br />
                      </Fragment>
                    );
                  } else {
                    return (
                      <Fragment key={childIndex}>
                        {processNewLines(processText(children[0].value))}
                      </Fragment>
                    );
                  }
                }
              );

              return (
                <div key={itemIndex}>
                  <article className={styles.item}>
                    <div className={styles.firstLine}>
                      <div className={styles.imageWrapper}>
                        <GatsbyImage
                          image={image.gatsbyImageData}
                          objectFit="cover"
                          alt=""
                          className={styles.image}
                        />
                      </div>
                      <div className={styles.headings}>
                        <h3 className={styles.nameHeading}>
                          {processText(heading)}
                        </h3>
                        <h4 className={styles.titleHeading}>
                          {processText(secondHeading)}
                        </h4>
                      </div>
                    </div>
                    <ScrollableText className={styles.textWrapper}>
                      <p className={styles.text}>{textArray}</p>
                    </ScrollableText>
                  </article>
                </div>
              );
            }
          )}
        </Slider>
      </div>
    </SectionContent>
  </section>
);

export default Reviews;
