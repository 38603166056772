import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import processText from "../utils/processText";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import scheduleBrand1Src from "../assets/schedule-brand-1.svg";
import getIdFromLink from "../utils/getIdFromLink";
import * as styles from "./schedule.module.css";

const Schedule = ({ link, heading, content }) => {
  const list =
    content[0].primaryText.childMarkdownRemark.htmlAst.children[0].children.reduce(
      (list, { tagName, children }) => {
        if (tagName === "li") {
          list.push(children[0].value.split("\n"));
        }

        return list;
      },
      []
    );

  return (
    <section id={getIdFromLink(link)} className={styles.section}>
      <SectionContent>
        <SectionHeading text={processText(heading)} />
        <div className={styles.content}>
          <div className={styles.firstPart}>
            <div className={styles.imageWrapper}>
              <StaticImage src="../assets/schedule.png" alt="" />
              <div className={styles.brandImageWrapper1}>
                <img
                  src={scheduleBrand1Src}
                  alt=""
                  className={styles.brandImage}
                />
              </div>
            </div>
          </div>
          <div className={styles.secondPart}>
            <div className={styles.listWrapper}>
              <ul className={styles.list}>
                {list.map(([itemHeading, itemText]) => (
                  <li key={itemHeading} className={styles.item}>
                    <strong className={styles.itemHeading}>
                      {itemHeading}
                    </strong>
                    <span className={styles.itemText}>{itemText}</span>
                  </li>
                ))}
              </ul>
              <div className={styles.brandImageWrapper2}>
                <StaticImage
                  src="../assets/schedule-brand-2.png"
                  alt=""
                  className={styles.brandImage}
                />
              </div>
            </div>
          </div>
        </div>
      </SectionContent>
    </section>
  );
};

export default Schedule;
