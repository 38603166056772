import React from "react";
import classNames from "classnames";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import getIdFromLink from "../utils/getIdFromLink";
import processText from "../utils/processText";
import Button from "../components/button";
import { CALLBACK_SECTION_LINK } from "../constants/constants";
import * as styles from "./tariffs.module.css";

const Tariffs = ({ link, heading, content }) => (
  <section id={getIdFromLink(link)} className={styles.section}>
    <SectionContent>
      <SectionHeading text={processText(heading)} />
      <ul className={styles.container}>
        {content[0].items.map(({ heading, secondHeading, mdText }, index) => {
          const isAccent = index === 0;
          const isPurple1 = index === 1;
          const isPurple2 = index === 2;

          return (
            <li
              key={index}
              className={classNames(styles.item, {
                [styles.itemAccent]: isAccent,
              })}
            >
              <h3
                className={classNames(styles.heading, {
                  [styles.headingPurple1]: isPurple1,
                  [styles.headingPurple2]: isPurple2,
                })}
              >
                {processText(heading)}
                <small>{processText(secondHeading)}</small>
              </h3>
              <ul className={styles.list}>
                {mdText.childMarkdownRemark.htmlAst.children[0].children
                  .filter(({ tagName }) => tagName === "li")
                  .map(({ children }, index) => {
                    const isDeleted = children[0].tagName === "del";

                    return (
                      <li
                        key={index}
                        className={classNames(styles.listItem, {
                          [styles.listItemDeleted]: isDeleted,
                        })}
                      >
                        {isDeleted ? (
                          <del>
                            {processText(children[0].children[0].value)}
                          </del>
                        ) : (
                          processText(children[0].value)
                        )}
                      </li>
                    );
                  })}
              </ul>
              <h4 className={styles.priceHeading}>
                {processText(
                  mdText.childMarkdownRemark.htmlAst.children[2].children[0]
                    .value
                )}
              </h4>
              <div className={styles.buttonWrapper}>
                <Button href={CALLBACK_SECTION_LINK} isWhite={isAccent}>
                  {processText(
                    mdText.childMarkdownRemark.htmlAst.children[4].children[0]
                      .children[0].value
                  )}
                </Button>
              </div>
            </li>
          );
        })}
      </ul>
    </SectionContent>
  </section>
);

export default Tariffs;
