import React from "react";
import getIdFromLink from "../utils/getIdFromLink";
import SectionHeading from "../components/sectionHeading";
import About from "../components/about";
import processText from "../utils/processText";
import Factors from "../components/factors";
import Goal from "../components/goal";
import SectionContent from "../components/sectionContent";
import * as styles from "./about.module.css";
import Premises from "../components/premises";

const AboutSection = ({ content, link, heading }) => {
  const data = content.reduce(
    (data, { __typename, primaryText, image, secondaryText, items }, index) => {
      if (index === 0 && __typename === "ContentfulContent") {
        const primaryItems = primaryText.childMarkdownRemark.htmlAst.children;

        data.image = image;
        data.heading = {
          tagName: primaryItems[0].tagName,
          value: processText(primaryItems[0].children[0].value),
        };
        data.paragraphs = [
          processText(primaryItems[2].children[0].value),
          processText(primaryItems[4].children[0].value),
        ];
        data.list = primaryItems[6].children.reduce((list, item) => {
          if (item.tagName === "li") {
            list.push(processText(item.children[0].value));
          }
          return list;
        }, []);
        data.factors = {
          heading: processText(
            secondaryText.childMarkdownRemark.htmlAst.children[0].children[0]
              .value
          ),
        };
      }

      if (index === 1 && __typename === "ContentfulListOfReferences") {
        data.factors.items = items.map(({ heading, icon }) => ({
          heading: processText(heading),
          icon,
        }));
      }

      if (index === 2 && __typename === "ContentfulContent") {
        const primaryItems = primaryText.childMarkdownRemark.htmlAst.children;
        const secondaryItems =
          secondaryText.childMarkdownRemark.htmlAst.children;

        data.goal = {
          icon: image,
          heading: processText(primaryItems[0].children[0].value),
          text: processText(primaryItems[2].children[0].value),
        };
        data.premises = {
          text: secondaryItems[0].children.map(
            ({ type, value, tagName: TagName, children }) =>
              type === "text" ? (
                `${processText(value)} `
              ) : (
                <TagName key={children[0].value}>
                  {processText(children[0].value)}
                </TagName>
              )
          ),
          items: secondaryItems[2].children.reduce((list, item) => {
            if (item.tagName === "li") {
              list.push(processText(item.children[0].value));
            }
            return list;
          }, []),
          textBoxWithIcon: {
            iconSrc: secondaryItems[4].children[0].properties.src,
            text: processText(secondaryItems[4].children[1].value),
          },
        };
      }

      if (index === 3 && __typename === "ContentfulListOfReferences") {
        data.premises.images = items.map(({ image }) => image);
      }

      return data;
    },
    {}
  );

  return (
    <section id={getIdFromLink(link)} className={styles.section}>
      <SectionContent>
        <SectionHeading text={processText(heading)} />
        <About
          image={data.image}
          heading={data.heading}
          paragraphs={data.paragraphs}
          list={data.list}
        />
        <Factors {...data.factors} />
      </SectionContent>
      <Goal {...data.goal} />
      <SectionContent>
        <Premises {...data.premises} />
      </SectionContent>
    </section>
  );
};

export default AboutSection;
