import React from "react";
import { Link } from "gatsby";
import getNavigationData from "../utils/getNavigationData";
import SectionContent from "../components/sectionContent";
import Logo from "../components/logo";
import NavigationLink from "../components/navigationLink";
import Phone from "../components/phone";
import scrollToSection from "../utils/scrollToSection";
import * as styles from "./footer.module.css";

const Footer = ({ content }) => {
  const { links, phone } = getNavigationData(content, true);

  return (
    <footer>
      <SectionContent>
        <div className={styles.content}>
          <nav className={styles.navigation}>
            <Link
              to="/"
              onClick={(e) => scrollToSection(e)}
              className={styles.logoLink}
            >
              <Logo isFooter />
            </Link>
            <Phone {...phone} isFooter />
            <ul className={styles.linksList}>
              {links.map((link) => (
                <li key={link.href} className={styles.linksListItem}>
                  <NavigationLink
                    {...link}
                    onClick={(e) => scrollToSection(e)}
                    isFooter
                  />
                </li>
              ))}
            </ul>
          </nav>
        </div>
      </SectionContent>
    </footer>
  );
};

export default Footer;
