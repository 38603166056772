import React from "react";
import classNames from "classnames";
import * as styles from "./sectionContent.module.css";

const SectionContent = ({ children, isFlex = false }) => (
  <div
    className={classNames(styles.sectionContent, {
      [styles.sectionContentFlex]: isFlex,
    })}
  >
    {children}
  </div>
);

export default SectionContent;
