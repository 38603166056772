import React, { Fragment } from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import getIdFromLink from "../utils/getIdFromLink";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import processText from "../utils/processText";
import processNewLines from "../utils/processNewLines";
import brandImageSrc1 from "../assets/values-brand-1.svg";
import brandImageSrc3 from "../assets/values-brand-3.svg";
import brandImageSrc4 from "../assets/values-brand-4.svg";
import brandImageSrc6 from "../assets/values-brand-6.svg";
import * as styles from "./values.module.css";

const Values = ({ link, heading, content }) => {
  return (
    <section id={getIdFromLink(link)} className={styles.section}>
      <SectionContent>
        <SectionHeading text={processText(heading)} />
        <div className={styles.listWrapper}>
          <ul className={styles.list}>
            {content[0].items.map(
              ({ heading, mdText, icon, image }, itemIndex) => {
                const textArray =
                  mdText.childMarkdownRemark.htmlAst.children.map(
                    ({ value, children }, childIndex) => {
                      if (value === "\n") {
                        return (
                          <Fragment key={childIndex}>
                            <br /> <br />
                          </Fragment>
                        );
                      } else {
                        return (
                          <Fragment key={childIndex}>
                            {processNewLines(processText(children[0].value))}
                          </Fragment>
                        );
                      }
                    }
                  );

                return (
                  <li key={itemIndex} className={styles.item}>
                    <div
                      className={styles.textWithHeading}
                      data-visual-index={`0${itemIndex + 1}`}
                    >
                      {itemIndex === 0 && (
                        <div className={styles.brandImageWrapper1}>
                          <img
                            src={brandImageSrc1}
                            alt=""
                            className={styles.brandImage}
                          />
                        </div>
                      )}
                      {itemIndex === 2 && (
                        <div className={styles.brandImageWrapper3}>
                          <img
                            src={brandImageSrc3}
                            alt=""
                            className={styles.brandImage}
                          />
                        </div>
                      )}
                      {itemIndex === 3 && (
                        <>
                          <div className={styles.brandImageWrapper4}>
                            <img
                              src={brandImageSrc4}
                              alt=""
                              className={styles.brandImage}
                            />
                          </div>
                          <div className={styles.brandImageWrapper5}>
                            <StaticImage
                              src="../assets/values-brand-5.png"
                              alt=""
                              className={styles.brandImage}
                            />
                          </div>
                        </>
                      )}
                      {itemIndex === 4 && (
                        <>
                          <div className={styles.brandImageWrapper6}>
                            <img
                              src={brandImageSrc6}
                              alt=""
                              className={styles.brandImage}
                            />
                          </div>
                          <div className={styles.brandImageWrapper7}>
                            <StaticImage
                              src="../assets/values-brand-7.png"
                              alt=""
                              className={styles.brandImage}
                            />
                          </div>
                          <div className={styles.brandImageWrapper8}>
                            <StaticImage
                              src="../assets/values-brand-8.png"
                              alt=""
                              className={styles.brandImage}
                            />
                          </div>
                        </>
                      )}
                      <h3 className={styles.heading}>{processText(heading)}</h3>
                      <span className={styles.iconWrapper}>
                        <img
                          src={icon.file.url}
                          alt=""
                          className={styles.icon}
                        />
                      </span>
                      <p className={styles.text}>{textArray}</p>
                    </div>
                    <div className={styles.imageWrapper}>
                      <GatsbyImage
                        image={image.gatsbyImageData}
                        alt=""
                        className={styles.image}
                      />
                      {itemIndex === 1 && (
                        <div className={styles.brandImageWrapper2}>
                          <StaticImage
                            src="../assets/values-brand-2.png"
                            alt=""
                            className={styles.brandImage}
                          />
                        </div>
                      )}
                    </div>
                  </li>
                );
              }
            )}
          </ul>
        </div>
      </SectionContent>
    </section>
  );
};

export default Values;
