import React from "react";
import SectionContent from "./sectionContent";
import goalSrc from "../assets/goal.svg";
import goalLargeSrc from "../assets/goal-large.svg";
import * as styles from "./goal.module.css";

const Goal = ({ icon, heading, text }) => (
  <section className={styles.section}>
    <SectionContent>
      <div className={styles.content}>
        <img src={icon.file.url} alt="" className={styles.icon} />
        <h3 className={styles.heading}>{heading}</h3>
        <p className={styles.text}>{text}</p>
      </div>
    </SectionContent>
    <img src={goalSrc} alt="" className={styles.image} />
    <img src={goalLargeSrc} alt="" className={styles.imageLarge} />
  </section>
);

export default Goal;
