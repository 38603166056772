import { useLayoutEffect, useState } from "react";
import { dequal } from "dequal/lite";
import isBrowser from "./isBrowser";

export const SIZES = { gtM: 1280 };

const getBreakpointsMap = () => {
  const width = isBrowser() ? window.innerWidth : 0;
  return Object.keys(SIZES).reduce((map, key) => {
    map[key] = width > SIZES[key];
    return map;
  }, {});
};

const useBreakpoints = () => {
  const [breakpointsMap, setBreakpointsMap] = useState(getBreakpointsMap);

  useLayoutEffect(() => {
    if (!isBrowser()) {
      return;
    }

    const handleResize = () => {
      setBreakpointsMap((breakpointsMap) => {
        const newBreakpointsMap = getBreakpointsMap();
        if (!dequal(breakpointsMap, newBreakpointsMap)) {
          return newBreakpointsMap;
        }
        return breakpointsMap;
      });
    };

    handleResize();
    window.addEventListener("resize", handleResize);

    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return breakpointsMap;
};

export default useBreakpoints;
