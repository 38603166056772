import React from "react";
import { Link } from "gatsby";
import classNames from "classnames";
import { CALLBACK_SECTION_LINK } from "../constants/constants";
import scrollToSection from "../utils/scrollToSection";
import * as styles from "./phone.module.css";

const Phone = ({ value, text, additionalText, isFooter = false }) => (
  <div
    className={classNames(styles.container, {
      [styles.containerFooter]: isFooter,
    })}
  >
    <a href={`tel:${value}`} className={styles.phone}>
      {text}
    </a>
    <Link
      to={CALLBACK_SECTION_LINK}
      onClick={scrollToSection}
      className={styles.caption}
    >
      {additionalText}
    </Link>
  </div>
);

export default Phone;
