import React from "react";
import { Field, Form } from "react-final-form";
import { FORM_ERROR } from "final-form";
import { isValidPhoneNumber } from "libphonenumber-js";
import createDecorator from "final-form-focus";
import classNames from "classnames";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import processText from "../utils/processText";
import Button from "../components/button";
import getIdFromLink from "../utils/getIdFromLink";
import { CALLBACK_SECTION_LINK } from "../constants/constants";
import * as styles from "./callback.module.css";

const focusOnError = createDecorator();

const Callback = ({ heading, secondaryHeading, content }) => {
  const inputs =
    content[0].primaryText.childMarkdownRemark.htmlAst.children[0].children.reduce(
      (inputs, { tagName, children }) => {
        if (tagName === "li") {
          inputs.push(processText(children[0].value));
        }

        return inputs;
      },
      []
    );
  const validate = (values) => {
    const errors = {};
    const errorText = "Введите корректное значение";

    if (
      !values[inputs[0]] ||
      !/^(?:[А-Яа-яA-Za-z]+\s){0,2}[А-Яа-яA-Za-z]+$/.test(
        values[inputs[0]].trim()
      )
    ) {
      errors[inputs[0]] = errorText;
    }

    if (!values[inputs[1]] || !isValidPhoneNumber(values[inputs[1]], "RU")) {
      errors[inputs[1]] = errorText;
    }

    if (
      !values[inputs[2]] ||
      isNaN(parseInt(values[inputs[2]])) ||
      parseInt(values[inputs[2]]) < 1 ||
      parseInt(values[inputs[2]]) > 18
    ) {
      errors[inputs[2]] = errorText;
    }

    return errors;
  };
  const onSubmit = async (values) => {
    let ipJSON;

    try {
      const response = await fetch("https://api.db-ip.com/v2/free/self");
      ipJSON = await response.json();
    } catch (ignore) {}

    try {
      await fetch("https://formsubmit.co/ajax/vgpreschool@yandex.com", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        referrerPolicy: "origin",
        body: JSON.stringify({
          ...values,
          _subject: "Поступила новая заявка на обратный звонок!",
          ...ipJSON,
        }),
      });
    } catch (ignore) {
      return { [FORM_ERROR]: "Submit fetch error" };
    }
  };

  return (
    <section
      id={getIdFromLink({ href: CALLBACK_SECTION_LINK })}
      className={styles.section}
    >
      <SectionContent>
        <Form
          onSubmit={onSubmit}
          decorators={[focusOnError]}
          validate={validate}
          render={({
            handleSubmit,
            submitting,
            submitSucceeded,
            submitFailed,
          }) => {
            const isDisabled = submitting || submitSucceeded;
            let buttonText;

            if (submitSucceeded) {
              buttonText =
                content[0].secondaryText.childMarkdownRemark.htmlAst.children[0]
                  .children[4].children[0].value;
            } else if (submitting) {
              buttonText =
                content[0].secondaryText.childMarkdownRemark.htmlAst.children[0]
                  .children[2].children[0].value;
            } else {
              buttonText =
                content[0].secondaryText.childMarkdownRemark.htmlAst.children[0]
                  .children[0].children[0].value;
            }

            return (
              <form className={styles.form} onSubmit={handleSubmit}>
                <div className={styles.mainContent}>
                  <h3 className={styles.heading}>
                    {processText(secondaryHeading)}
                  </h3>
                  <SectionHeading text={processText(heading)} />
                </div>
                <div className={styles.inputs}>
                  <Field
                    name={processText(inputs[0])}
                    type="text"
                    render={({ input, meta }) => (
                      <div className={styles.inputWrapper}>
                        <input
                          name={input.name}
                          type={input.type}
                          placeholder={processText(inputs[0])}
                          value={input.value}
                          onChange={input.onChange}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          disabled={isDisabled}
                          maxLength={64}
                          className={classNames(styles.input, {
                            [styles.inputInvalid]: meta.error && meta.touched,
                          })}
                        />
                        {meta.error && meta.touched && (
                          <div className={styles.error}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                  <Field
                    name={processText(inputs[1])}
                    type="tel"
                    render={({ input, meta }) => (
                      <div className={styles.inputWrapper}>
                        <input
                          name={input.name}
                          type={input.type}
                          placeholder={processText(inputs[1])}
                          value={input.value}
                          onChange={input.onChange}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          disabled={isDisabled}
                          className={classNames(styles.input, {
                            [styles.inputInvalid]: meta.error && meta.touched,
                          })}
                        />
                        {meta.error && meta.touched && (
                          <div className={styles.error}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                  <Field
                    name={processText(inputs[2])}
                    type="number"
                    render={({ input, meta }) => (
                      <div className={styles.inputWrapper}>
                        <input
                          name={input.name}
                          type={input.type}
                          placeholder={processText(inputs[2])}
                          value={input.value}
                          onChange={input.onChange}
                          onFocus={input.onFocus}
                          onBlur={input.onBlur}
                          disabled={isDisabled}
                          min={1}
                          max={18}
                          maxLength={2}
                          className={classNames(styles.input, {
                            [styles.inputInvalid]: meta.error && meta.touched,
                          })}
                        />
                        {meta.error && meta.touched && (
                          <div className={styles.error}>{meta.error}</div>
                        )}
                      </div>
                    )}
                  />
                </div>
                <div className={styles.mainContent}>
                  <div className={styles.buttonWrapper}>
                    <Button type="submit" isDisabled={isDisabled}>
                      {processText(buttonText)}
                    </Button>
                    {submitFailed && (
                      <div className={styles.error}>
                        Произошла ошибка, попробуйте повторить
                      </div>
                    )}
                  </div>
                  <p className={styles.text}>
                    {processText(
                      content[0].secondaryText.childMarkdownRemark.htmlAst
                        .children[2].children[0].value
                    )}
                  </p>
                </div>
              </form>
            );
          }}
        />
      </SectionContent>
    </section>
  );
};

export default Callback;
