import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import List from "./list";
import brandImage1Src from "../assets/premises-brand-1.svg";
import brandImage2Src from "../assets/premises-brand-2.svg";
import * as styles from "./premises.module.css";

const BRAND_IMAGES_SRC_MAP = {
  1: brandImage1Src,
  2: brandImage2Src,
};

const Premises = ({ text, items, images, textBoxWithIcon }) => (
  <section className={styles.section}>
    <div className={styles.firstPart}>
      <p className={styles.text}>{text}</p>
      <div className={styles.listWrapper}>
        <List items={items} isLinear />
      </div>
    </div>
    <div className={styles.secondPart}>
      {images.map(({ gatsbyImageData }, index) => (
        <div key={index} className={styles.imageWrapper}>
          <div className={styles.brandImageWrapper}>
            {BRAND_IMAGES_SRC_MAP[index + 1]?.includes("svg") ? (
              <img
                src={BRAND_IMAGES_SRC_MAP[index + 1]}
                alt=""
                className={styles.brandImage}
              />
            ) : (
              <StaticImage
                src="../assets/premises-brand-3.png"
                alt=""
                className={styles.brandImage}
              />
            )}
          </div>
          <GatsbyImage image={gatsbyImageData} alt="" />
        </div>
      ))}
    </div>
    <p className={styles.textBoxWithIcon}>
      <img src={textBoxWithIcon.iconSrc} alt="" className={styles.icon} />
      {textBoxWithIcon.text}
    </p>
  </section>
);

export default Premises;
