import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import getIdFromLink from "../utils/getIdFromLink";
import processText from "../utils/processText";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import brandImageSrc2 from "../assets/enrollment-process-brand-2.svg";
import * as styles from "./enrollmentProcess.module.css";

const EnrollmentProcess = ({ link, heading, content }) => {
  return (
    <section id={getIdFromLink(link)} className={styles.section}>
      <SectionContent>
        <SectionHeading text={processText(heading)} />
        <div className={styles.listWrapper}>
          <ul className={styles.list}>
            {content[0].items.map(({ icon, heading, secondHeading }, index) => (
              <li key={index} className={styles.item}>
                <div className={styles.iconWrapper}>
                  <img src={icon.file.url} alt="" className={styles.icon} />
                </div>
                <div className={styles.textWithHeading}>
                  <h3
                    className={styles.heading}
                    data-visual-index={`0${index + 1}`}
                  >
                    {processText(heading)}
                  </h3>
                  {secondHeading && (
                    <p className={styles.text}>{processText(secondHeading)}</p>
                  )}
                </div>
              </li>
            ))}
          </ul>
          <div className={styles.brandImageWrapper1}>
            <StaticImage
              src="../assets/enrollment-process-brand-1.png"
              alt=""
              className={styles.brandImage}
            />
          </div>
          <div className={styles.brandImageWrapper2}>
            <img src={brandImageSrc2} alt="" className={styles.brandImage} />
          </div>
        </div>
      </SectionContent>
    </section>
  );
};

export default EnrollmentProcess;
