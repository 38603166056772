// extracted by mini-css-extract-plugin
export var brandImage = "contacts-module--brandImage--Jcys4";
export var brandImageWrapper1 = "contacts-module--brandImageWrapper1--cx7B5";
export var brandImageWrapper2 = "contacts-module--brandImageWrapper2--9SGBA";
export var brandImageWrapper3 = "contacts-module--brandImageWrapper3--F9pQs";
export var brandImageWrapper4 = "contacts-module--brandImageWrapper4--EgzIj";
export var brandImageWrapper5 = "contacts-module--brandImageWrapper5--9vhzi";
export var brandImageWrapper6 = "contacts-module--brandImageWrapper6--YKjK5";
export var content = "contacts-module--content--eXaEG";
export var icon = "contacts-module--icon--Hee5y";
export var iconWrapper = "contacts-module--iconWrapper--VXl-a";
export var item = "contacts-module--item--rFyPk";
export var link = "contacts-module--link--S4JZE";
export var list = "contacts-module--list--7nb-y";
export var section = "contacts-module--section--XYvcD";