import processText from "./processText";

const getNavigationData = (content, withFooterOnly = false) =>
  content.reduce((data, { __typename, links, secondaryText, items }) => {
    if (__typename === "ContentfulContent") {
      data.links = links.map(({ href, text, accent, footerOnly }) => ({
        href,
        text: processText(text),
        accent,
        footerOnly,
      }));

      if (!withFooterOnly) {
        data.links = data.links.filter(({ footerOnly }) => !footerOnly);
      }

      data.phone = {
        ...data.phone,
        additionalText: processText(
          secondaryText.childMarkdownRemark.rawMarkdownBody
        ),
      };
    }
    if (__typename === "ContentfulListOfReferences") {
      const phone = items.find((contactItem) => contactItem.type === "phone");
      if (phone) {
        data.phone = { ...data.phone, ...phone };
      }
    }

    return data;
  }, {});

export default getNavigationData;
