import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import processText from "../utils/processText";
import getIdFromLink from "../utils/getIdFromLink";
import brandImage1Src from "../assets/contacts-brand-1.svg";
import brandImage5Src from "../assets/contacts-brand-5.svg";
import processNewLines from "../utils/processNewLines";
import * as styles from "./contacts.module.css";

const renderContactsList = (items) => (
  <ul className={styles.list}>
    {items.map(({ icon, text, type, value }, index) => {
      let href = value;

      if (type === "phone") {
        href = `tel:${value}`;
      } else if (type === "email") {
        href = `mailto:${value}`;
      }

      const additionalProps = {};

      if (href.startsWith("http")) {
        additionalProps.target = "_blank";
        additionalProps.rel = "noopener noreferrer";
      }

      return (
        <li key={index} className={styles.item}>
          <a href={href} className={styles.link} {...additionalProps}>
            <div className={styles.iconWrapper}>
              <img src={icon.file.url} alt="" className={styles.icon} />
            </div>
            <div>{processNewLines(text || value, true)}</div>
          </a>
        </li>
      );
    })}
  </ul>
);

const Contacts = ({ link, heading, content }) => (
  <section id={getIdFromLink(link)} className={styles.section}>
    <SectionContent>
      <SectionHeading text={processText(heading)} />
      <div className={styles.content}>
        <div className={styles.brandImageWrapper1}>
          <img src={brandImage1Src} alt="" className={styles.brandImage} />
        </div>
        <div className={styles.brandImageWrapper2}>
          <StaticImage
            src="../assets/contacts-brand-2.png"
            alt=""
            className={styles.brandImage}
          />
        </div>
        <div className={styles.brandImageWrapper3}>
          <StaticImage
            src="../assets/contacts-brand-3.png"
            alt=""
            className={styles.brandImage}
          />
        </div>
        <div className={styles.brandImageWrapper4}>
          <StaticImage
            src="../assets/contacts-brand-4.png"
            alt=""
            className={styles.brandImage}
          />
        </div>
        <div className={styles.brandImageWrapper5}>
          <img src={brandImage5Src} alt="" className={styles.brandImage} />
        </div>
        <div className={styles.brandImageWrapper6}>
          <StaticImage
            src="../assets/contacts-brand-6.png"
            alt=""
            className={styles.brandImage}
          />
        </div>
        {renderContactsList(content[0].items)}
        {renderContactsList(content[1].items)}
      </div>
    </SectionContent>
  </section>
);

export default Contacts;
