import React, { Fragment } from "react";

const processNewLines = (text, isEscaped = false) => {
  const textArray = text.split(isEscaped ? "\\n" : "\n");

  if (textArray.length === 1) {
    return textArray[0];
  }

  return textArray.map((item, index) => (
    <Fragment key={index}>
      {item}
      {index !== textArray.length - 1 && <br />}
    </Fragment>
  ));
};

export default processNewLines;
