import React from "react";
import classNames from "classnames";
import * as styles from "./burgerButton.module.css";

const BurgerButton = ({ isOpened, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={classNames(styles.burgerButton, {
      [styles.burgerButtonOpened]: isOpened,
    })}
  >
    <span className={styles.line} />
    <span className={styles.line} />
    <span className={styles.line} />
  </button>
);

export default BurgerButton;
