import React from "react";
import SimpleBar from "simplebar-react";
import "simplebar-react/dist/simplebar.min.css";
import "./scrollableText.css";

const ScrollableText = ({ children, ...props }) => (
  <SimpleBar autoHide={false} {...props}>
    {children}
  </SimpleBar>
);

export default ScrollableText;
