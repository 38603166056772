import React from "react";
import { Helmet } from "react-helmet";

const Head = ({
  title = "",
  description = "",
  keywords = "",
  noIndex = false,
  image = "",
}) => {
  const imageUrl = image ? `https:${image.file.url}` : "";

  return (
    <Helmet htmlAttributes={{ lang: "ru" }}>
      <title>{title}</title>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords} />
      <meta name="robots" content={noIndex ? "noindex, nofollow" : "index"} />
      <meta property="og:title" name="title" content={title} />
      <meta
        property="og:description"
        name="description"
        content={description}
      />
      <meta property="og:image" name="image" content={imageUrl} />
      <script>{`(function(w, d, u, i, o, s, p) { if (d.getElementById(i)) { return; } w['MangoObject'] = o; w[o] = w[o] || function() { (w[o].q = w[o].q || []).push(arguments) }; w[o].u = u; w[o].t = 1 * new Date(); s = d.createElement('script'); s.async = 1; s.id = i; s.src = u; s.charset = 'utf-8'; p = d.getElementsByTagName('script')[0]; p.parentNode.insertBefore(s, p); }(window, document, '//widgets.mango-office.ru/widgets/mango.js', 'mango-js', 'mgo')); mgo({multichannel: {id: 17087}});`}</script>
    </Helmet>
  );
};

export default Head;
