import React from "react";
import Head from "./head";
import "../styles/global.css";
import * as styles from "./page.module.css";

const Page = (props) => (
  <div className={styles.page}>
    <Head {...props} />
    {props.children}
  </div>
);

export default Page;
