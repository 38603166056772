// extracted by mini-css-extract-plugin
export var brandImage = "enrollmentProcess-module--brandImage--HCNzA";
export var brandImageWrapper1 = "enrollmentProcess-module--brandImageWrapper1--tiW7J";
export var brandImageWrapper2 = "enrollmentProcess-module--brandImageWrapper2--PF1GG";
export var heading = "enrollmentProcess-module--heading--jRwML";
export var icon = "enrollmentProcess-module--icon--Nd1f6";
export var iconWrapper = "enrollmentProcess-module--iconWrapper---pREC";
export var item = "enrollmentProcess-module--item--w0mU9";
export var list = "enrollmentProcess-module--list--9-pfa";
export var listWrapper = "enrollmentProcess-module--listWrapper--97FGo";
export var section = "enrollmentProcess-module--section--O7tng";
export var text = "enrollmentProcess-module--text--n8JjE";
export var textWithHeading = "enrollmentProcess-module--textWithHeading--u5rUa";