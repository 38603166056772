import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import processText from "../utils/processText";
import SectionContent from "../components/sectionContent";
import SectionHeading from "../components/sectionHeading";
import getIdFromLink from "../utils/getIdFromLink";
import * as styles from "./educationalProcess.module.css";

const EducationalProcess = ({ link, heading, content }) => (
  <section id={getIdFromLink(link)} className={styles.section}>
    <SectionContent>
      <SectionHeading text={processText(heading)} />
      <ul className={styles.list}>
        {content[0].items.map(({ icon, heading, secondHeading }) => (
          <li key={heading} className={styles.item}>
            <div className={styles.iconWrapper}>
              <StaticImage
                src="../assets/educational-process.png"
                alt=""
                className={styles.backgroundIcon}
              />
              <img src={icon.file.url} alt="" className={styles.icon} />
            </div>
            {processText(heading)}
            {secondHeading && (
              <>
                <br />
                {processText(secondHeading)}
              </>
            )}
          </li>
        ))}
      </ul>
    </SectionContent>
  </section>
);

export default EducationalProcess;
