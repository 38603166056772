// extracted by mini-css-extract-plugin
export var brandImage = "premises-module--brandImage--TZhl5";
export var brandImageWrapper = "premises-module--brandImageWrapper--7qTBG";
export var firstPart = "premises-module--firstPart--VrAlV";
export var icon = "premises-module--icon--lv8l+";
export var imageWrapper = "premises-module--imageWrapper--tG74U";
export var listWrapper = "premises-module--listWrapper--lTXI6";
export var secondPart = "premises-module--secondPart--EQJD0";
export var section = "premises-module--section--I-LfD";
export var text = "premises-module--text--E0JyO";
export var textBoxWithIcon = "premises-module--textBoxWithIcon--qh1AD";