import React from "react";
import { GatsbyImage, StaticImage } from "gatsby-plugin-image";
import TextWithHeading from "./textWithHeading";
import List from "./list";
import * as styles from "./about.module.css";

const About = ({ image, heading, paragraphs, list }) => (
  <section className={styles.section}>
    <div className={styles.firstPart}>
      <div className={styles.backgroundImageWrapper}>
        <StaticImage src="../assets/about-background.png" alt="" />
      </div>
      <div className={styles.backgroundImageLargeWrapper}>
        <StaticImage src="../assets/about-background-large.png" alt="" />
      </div>
      <div className={styles.contentImageWrapper}>
        <GatsbyImage image={image.gatsbyImageData} alt="" />
      </div>
    </div>
    <div className={styles.secondPart}>
      <TextWithHeading
        TagName={heading.tagName}
        heading={heading.value}
        text={paragraphs}
      />
      <div className={styles.listWrapper}>
        <List items={list} />
      </div>
    </div>
  </section>
);

export default About;
