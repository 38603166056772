// extracted by mini-css-extract-plugin
export var buttonWrapper = "tariffs-module--buttonWrapper--MYwQW";
export var container = "tariffs-module--container--7wrzA";
export var heading = "tariffs-module--heading--mbgTs";
export var headingPurple1 = "tariffs-module--headingPurple1--un2cv";
export var headingPurple2 = "tariffs-module--headingPurple2--aceO7";
export var item = "tariffs-module--item--SUE8T";
export var itemAccent = "tariffs-module--itemAccent--9np7P";
export var list = "tariffs-module--list--fdC8o";
export var listItem = "tariffs-module--listItem--s57lJ";
export var listItemDeleted = "tariffs-module--listItemDeleted--ptbIg";
export var priceHeading = "tariffs-module--priceHeading--p8Xkc";
export var section = "tariffs-module--section--Cy0nx";